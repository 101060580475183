<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp mb-5">
      <b-col md="12" lg="7" class="text-left">
        <h1 class="mb-4">{{ $t('confirm_pay.pay_order') }}</h1>
        <b-row class>
          <b-col cols="12">
            <b-row align-v="center" class="mb-3">
              <b-col cols="2" md="1">
                <span class="number number_1">1</span>
              </b-col>
              <b-col cols="10" md="11">
                <p class="h-100 mb-0 ml-2">Укажите название сервиса / операции.</p>
              </b-col>
            </b-row>
            <b-row align-v="center" class="mb-3">
              <b-col cols="2" md="1">
                <span class="number number_2">2</span>
              </b-col>
              <b-col cols="10" md="11">
                <p class="h-100 mb-0 ml-2">Введите стоимость сервиса, который вы хотите оплатить, выберите валюту и
                  способ оплаты.</p>
              </b-col>
            </b-row>

            <b-row align-v="center" class="mb-3">
              <b-col cols="2" md="1">
                <span class="number number_3">3</span>
              </b-col>
              <b-col cols="10" md="11">
                <p class="mb-0 ml-2">Система рассчитает сумму к оплате с учетом комиссии GCTransfer в зависимости от
                  указанной стоимости.</p>
              </b-col>
            </b-row>
            <b-row align-v="center" class="mb-3">
              <b-col cols="2" md="1">
                <span class="number number_3">4</span>
              </b-col>
              <b-col cols="10" md="11">
                <p class="mb-0 ml-2">Подтвердите сумму и перейдите к оплате.</p>
              </b-col>
            </b-row>
            <b-row align-v="center" class="mb-3">
              <b-col cols="2" md="1">
                <span class="number number_4">5</span>
              </b-col>
              <b-col cols="10" md="11">
                <p class="h-100 mb-0 ml-2">
                  После оплаты свяжитесь с нами
                  <a href="https://t.me/gctransfer_support_bot" target="_blank"
                    rel="nofollow norefferer">@gctransfer_support_bot</a>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="12" lg="5" class="text-left" v-if="confirm != true">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <h5 class="text-left" v-b-visible="handleVisibility2">{{ $t('confirm_pay.payment') }}</h5>
            <b-form>
              <b-form-group>
                <label for="input-email" class="mb-0 mt-4">Email</label>
                <b-input v-model="email" :disabled="disabled_input" placeholder="ivan@gmail.com" type="text"
                  id="input-email" @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-telegram" class="mb-0">Telegram</label>
                <b-input v-model="telegram" :disabled="disabled_input" placeholder="Ник в телеграм (@username)"
                  type="text" id="input-telegram" @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-name" class="mb-0">Название сервиса</label>
                <b-input v-model="name_service" :disabled="disabled_input" placeholder="Spotify" type="text"
                  id="input-name" @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-price" class="mb-0">Стоимость</label>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <b-input v-model="price_service" :disabled="disabled_input" placeholder="199" type="number"
                    id="input-price" class @input="calculate"></b-input>
                  <b-form-select v-model="selected_service_currency" :options="['USD', 'EUR', 'RUB']" class="ml-2"
                    @input="calculate"></b-form-select>
                </div>
              </b-form-group>

              <b-form-group class="mt-2">
                <label for="input-type-pay" class="mb-0">Способ оплаты</label>
                <b-form-select v-model="selected" id="input-type-pay" :options="options" @input="calculate">
                </b-form-select>
              </b-form-group>
              <label for="input-name" class="mb-0">Итого</label>
              <div class="d-flex flex-row justify-content-center mt-0 mb-4 align-items-center">
                <b-input v-model="amount" disabled min="50" type="number" @input="calculate"></b-input>
                <img src="@/assets/img/dollar.png" width="20" style="position: absolute;right: 56%;opacity:1" />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled></b-input>

                <img src="@/assets/img/rub.svg" width="20" v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:1" />
                <img src="@/assets/img/dollar.png" width="20"
                  v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'"
                  style="position: absolute;right: 10%;opacity:1" />
              </div>
              <b-row class="text-center justify-content-center">
                <p v-if="individual"><small>Для расчета индивидуального размера комиссии свяжитесь с <a href="https://t.me/gctransfer_support_bot" target="_blank">
                      поддержкой</a></small></p>
                <p><small>Итоговая сумма оплаты рассчитывается в $ (USD).</small></p>
                <b-col cols="9">
                  <b-button class="btn btn-primary w-100 btn-price-card mb-2" :disabled="active == 0"
                    @click="nextStepPay">{{ $t('confirm_pay.confirm') }}</b-button>
                </b-col>
                <p class="text-left mt-3 pl-2 pr-2"><small v-if="selected == 'Qiwi'">! При оплате через Qiwi
                    максимальная сумма 1 платежа – 15 000 RUB. Если сумма вашего платежа с учетом комиссии сервиса
                    больше 15 000 RUB, разделите платеж на несколько частей.</small></p>

              </b-row>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="5" class="text-left" v-if="confirm & (step != 4)">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <h5 class="mb-0 text-left" v-b-visible="handleVisibility2">{{ $t('confirm_pay.payment') }}</h5>
            <b-form>
              <b-form-group>
                <label for="input-email" class="mb-0 mt-4">Email</label>
                <b-input v-model="email" disabled placeholder="ivan@gmail.com" type="text" id="input-email"
                  @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-telegram" class="mb-0">Telegram</label>
                <b-input v-model="telegram" disabled placeholder="Ник в телеграм (@username)" type="text"
                  id="input-telegram" @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-name" class="mb-0 mt-4">Название сервиса</label>
                <b-input v-model="name_service" disabled placeholder="Spotify" type="text" id="input-name"
                  @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-price" class="mb-0">Стоимость</label>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <b-input v-model="price_service" disabled placeholder="199" type="number" id="input-price" class
                    @input="calculate"></b-input>
                  <b-form-select v-model="selected_service_currency" :options="['USD', 'EUR']" disabled class="ml-2"
                    @input="calculate"></b-form-select>
                </div>
              </b-form-group>

              <b-form-group class="mt-2">
                <label for="input-type-pay" class="mb-0">Способ оплаты</label>
                <b-form-select v-model="selected" id="input-type-pay" :options="options" disabled @input="calculate">
                </b-form-select>
              </b-form-group>
              <label for="input-name" class="mb-0">Итого</label>
              <div class="d-flex flex-row justify-content-center mt-0 mb-4 align-items-center">
                <b-input v-model="amount" disabled min="50" type="number" @input="calculate"></b-input>
                <img src="@/assets/img/dollar.png" width="20" style="position: absolute;right: 56%;opacity:1" />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled></b-input>

                <img src="@/assets/img/rub.svg" width="20" v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:1" />
                <img src="@/assets/img/dollar.png" width="20"
                  v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'"
                  style="position: absolute;right: 10%;opacity:1" />
              </div>
              <b-row class="text-center justify-content-center">
                <b-col cols="9">
                  <a v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'" :href="link" target="_blank"
                    class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay')
                    }}</a>

                  <a v-else-if="selected == 'Qiwi'" :href="link" target="_blank"
                    class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay')
                    }}</a>

                  <a v-else :href="link" target="_blank" class="btn btn-primary btn-price-confirm w-75 mb-2"
                    @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>
                </b-col>
              </b-row>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { httpClient } from "../HttpClientV1/HttpClient";
export default {
  name: "Home",
  data() {
    return {
      individual: false,
      email: null,
      telegram: null,
      name_service: null,
      price_service: null,
      selected_service_currency: 'USD',
      selectedTafir: 6,
      isVisibleCard1: false,
      isVisibleCard2: false,
      isVisibleCard3: false,
      selected: null,
      confirm: false,
      error_pay: null,
      amount: 0,
      address: null,
      rub: 0,
      active: 0,
      step: 2,
      link: null,
      disabled_input: false,
      size: 90,
      interval: null,
      color: "#5598fb",
      amount_pay: null,
      order_id: null,
      options: [
        { value: null, text: this.$i18n.t('confirm_pay.choose_payment') },
        { value: "Qiwi", text: "Qiwi / Card" },
        // { value: "Mastercard (комиссия)", text: "Mastercard (комиссия)" },
        //  { value: "юMoney", text: "юMoney" },
        // { value: "Card (Visa/MasterCard) - AliKassa", text: "Card (Visa/MasterCard) - AliKassa" },
        // { value: "Qiwi (Wallet) - AliKassa", text: "Qiwi (Wallet) - AliKassa" },
        // { value: "юMoney (Wallet) - AliKassa", text: "юMoney (Wallet) - AliKassa" },
        { value: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud", text: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud" },
        //{ value: "ltc", text: "LTC" },
      ],
    };
  },
  methods: {
    handleVisibility1(isVisibleCard1) {
      this.isVisibleCard1 = isVisibleCard1;
    },

    handleVisibility2(isVisibleCard2) {
      this.isVisibleCard2 = isVisibleCard2;
    },

    handleVisibility3(isVisibleCard3) {
      this.isVisibleCard3 = isVisibleCard3;
    },
    goProfile() {
      this.$router.push("/profile");
    },
    calculate() {

      this.active = 0;

      localStorage.setItem("account", this.selectedTafir);
      if (this.selected != null && this.selectedTafir != null) {
        httpClient
          .get(
            "api/convertCurrencyServicePay?cur=" + this.selected + "&price_service=" + this.price_service + '&currency_service=' + this.selected_service_currency
          )
          .then((resp) => {
            if (resp.data.total >= 5000) {
              this.individual = true
              this.active = false

              this.rub = resp.data.total_in_pay
              this.amount = resp.data.total
            }
            else {
              this.individual = false
              this.rub = resp.data.total_in_pay
              this.amount = resp.data.total
              if (this.name_service != null && this.price_service != null && this.email != null && this.telegram != null) {
                this.active = 1
              }
            }
          }
          );
      }
    },
    nextStepPay() {
      if (this.selected != null && this.selectedTafir != null) {

        httpClient
          .post("api/confirmPay", {
            type_pay: this.selected,
            amount: this.rub,
            plan_id: this.selectedTafir,
            name_service: this.name_service,
            price_service: this.price_service,
            currency_service: this.selected_service_currency,
            total: this.amount,
            email: this.email,
            telegram: this.telegram
          })
          .then((resp) => {
            if (resp.data.status != "error") {
              this.link = resp.data.link
              try {

                window.open(this.link, '_blank').focus()
              }
              catch {
                console.error('error')
              }
              (this.address = resp.data.address),
                (this.step = 3),
                localStorage.setItem("order", resp.data.order_id),
                (this.order_id = resp.data.order_id),
                (this.rub = resp.data.ammount_usd),

                (this.loadedQR = true),
                (this.confirm = true);
              this.sendConfirm();
            } else {
              this.error_pay = resp.data.msg;
            }
          });
      } else {
        if (this.selected == null) {
          this.error_pay = this.$i18n.t('confirm_pay.choose_payment');
        } else {
          this.error_pay = this.$i18n.t('confirm_pay.specify_plan');
        }
      }
    },
    sendConfirm() {
      var self = this;
      self.interval = setInterval(function () {
        httpClient
          .get("api/statusorder_confirm?id=" + localStorage.getItem("order"))
          .then((resp) => {
            if (resp.data.status == "ok") {
              if (self.step != 4) {
                (self.step = 4),
                  self.confirms(),
                  (self.order_id = localStorage.getItem("order"));
                window.clearInterval(self.interval);
              }
            }
          });
      }, 2000);
    },

  },
  mounted() {
    this.selectedTafir = 6;
    this.calculate();
  },
};
</script>

<template>
  <b-container fluid>
    <div>
      <!-- As a link -->
      <b-navbar variant="faded" type="light" toggleable='sm' class="mb-4">
        <b-navbar-brand to="/">
          <b-img-lazy left v-bind="mainProps" :src="loadLogo"></b-img-lazy>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>


          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item class=" w-100 mb-2 mr-2" @click="setRuLocale"><img src="@/assets/img/ru.png" width="20" />
            </b-nav-item>
            <b-nav-item class="w-100 mb-2 mr-2" @click="setEnLocale"><img src="@/assets/img/en.png" width="20" />
            </b-nav-item>
            <b-nav-item to="/partners" class="btn btn-primary btn-nav w-100 mb-2 mr-2">{{ $t('partners') }}</b-nav-item>
            <b-nav-item to="/profile" class="btn btn-primary btn-nav w-100 mb-2 mr-2">{{ $t('confirm_pay.orders') }}
            </b-nav-item>
            <b-nav-item class="btn btn-danger btn-logout w-100 mb-2" @click="logout" v-if="isLoggedIn()">
              {{ $t('auth.log_out') }}</b-nav-item>
            <b-nav-item class="btn btn-danger btn-logout w-100 mb-2" @click="logout" v-else>{{ $t('auth.login') }}
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-container style="min-height:70vh">
      <router-view />
    </b-container>

    <b-container class="mb-5 mt-5">
      <b-row
        class="d-flex justify-content-center align-items-center align-content-center pl-3 pr-3 animate__animated animate__backInUp">
        <a href="https://gctransfer.co/" target="_blank" class="pr-3 nav-link">gctransfer.co</a>
        <a href="https://t.me/gctransfer_support_bot" target="_blank" class="nav-link">

          <img src="@/assets/img/telegram.svg" width="20" style="position: relative;top: -1px;" /> support</a>
      </b-row>

    </b-container>
  </b-container>
</template>
<script>
import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      mainProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: '#f0f7fd',
        width: 200,
        height: 70,
        class: 'my-0'
      }
    }
  },
  computed: {
    loadLogo() {
      return process.env.VUE_APP_API_SERVER + "static/img/logoGC.png"
    }
  },
  methods: {
    ...mapGetters(
      [
        'isLoggedIn'
      ]
    ),
    logout() {
      console.log('logout')
      this.$store.dispatch('logout')

      this.$router.push('/user/login')
    },
    setRuLocale() {
      this.$i18n.locale = "ru";
      localStorage.setItem("locale", "ru");
      localize("ru", ru);
    },
    setEnLocale() {
      this.$i18n.locale = "en";
      localStorage.setItem("locale", "en");
      localize("en", en);
    },
  }
}
</script>
<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-start align-items-center align-content-center animate__animated animate__backInUp mb-5"
    >
      <b-col md="12">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <p style="color: #353945" class="ft14">{{ $t("settings.Your reward") }}</p>
            <h3 class="ft40" style="margin-top:-10px">
              <b>
                {{ ref_balance.toLocaleString() }}
                <span style="color: #5797f8;">USD</span>
              </b>
            </h3>
            <p>{{ $t("settings.REFERAL LINK") }}</p>
            <span class="ft14" style="color: #777E91">
              {{ $t("settings.You get") }} 15%
              {{
                $t("settings.of all commissions from payments of your referrals")
              }}
              <br />
            </span>
            <b-input v-model="ref_link" readonly class="mt-3"></b-input>
          </b-card-body>
        </b-card>
      </b-col>
      
    </b-row>
  </div>
</template>
<script>
import { DotLoader } from "@saeris/vue-spinners";
import { httpClient } from "../HttpClientV1/HttpClient";
export default {
  components: {
    DotLoader
  },
  data() {
    return {
      tableData: null,
      rows: 0,
      ref_link: 'https://my.gctransfer.co/?ref=',
      perPage: 10,
      currentPage: 1,
      ref_balance: 0,
      msg: this.$i18n.t('home.No_data'),
      tableColumn: [
        {
          key: "id",
          label: 'Order id',
          sortable: false,
          class: "table-text-tran1",
        },
        {
          key: "created",
          label: "Date",
          sortable: false,
          class: "table-text-tran1",
        },
        {
          key: "link",
          label: "Link",
          sortable: false,
          class: "table-text-tran1",
        },
      ],
    };
  },
  methods: {
    buyAcc() {
      this.$router.push('/')
    },
    userData() {
      httpClient
        .post('api/user/data')
        .then((response) => {
          this.ref_link = 'https://my.gctransfer.co/?ref=' + response.data.code
          this.ref_balance = response.data.balance
        })
    },
    loadTableData() {
      httpClient
        .get("api/orders")
        .then((resp) => {
          this.tableData = resp.data
          this.rows = this.tableData.length
        })
        .catch(function (error) {
          if (error.response) {
            this.msg = error.response.data.msg
          }
        })
    }
  },
  mounted() {
    try {
      this.userData()
    }
    catch {
      console.log('Error loading data')
    }
    this.loadTableData()
  }
};
</script>

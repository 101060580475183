import Home from '../views/Home.vue'
import Partners from '../views/Partners.vue'
import Registration from '../views/Registration.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Profile from '../views/Profile.vue'
import ConfirmPay from '../views/ConfirmPay.vue'
import ConfirmPayService from '../views/ConfirmPayService.vue'
import Activate from '../views/Activate.vue'
import EmailConfirm from '../views/EmailConfirm.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {layout: 'main',requiresAuth: false}
  },
  {
    path: '/pay',
    name: 'Pay',
    component: ConfirmPayService,
    meta: {layout: 'service',requiresAuth: false}
  },
  {
    path: '/pay/confirm',
    redirect: '/pay'
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {layout: 'main',requiresAuth: true}
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    meta: {layout: 'main',requiresAuth: true}
  },
  {
    path: '/user/email/confirm',
    name: 'EmailConfirm',
    component: EmailConfirm,
    meta: {layout: 'main',requiresAuth: true}
  },
  {
    path: '/user/activate',
    name: 'Activate',
    component: Activate,
    meta: {layout: 'empty',requiresAuth: false}
  },
  {
    path: '/confirmPay',
    name: 'ConfirmPay',
    component: ConfirmPay,
    meta: {layout: 'main',requiresAuth: true}
  },
  {
    path: '/user/registration',
    name: 'Registration',
    component: Registration,
    meta: {layout: 'empty',requiresAuth: false}
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    meta: {layout: 'empty',requiresAuth: false}
  },
  {
    path: '/user/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {layout: 'empty',requiresAuth: false}
  }
]

export default routes

<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp mb-5"
      style="height:100%"
    >
      <b-col md="6" class="text-center">
        <h1>{{ $t('home.buy_acc')}}</h1>
        <p class="text-wrap">{{ $t('home.p_head_select_part1')}}<br>
        <span class="text-wrap">{{ $t('home.p_head_select_part2')}}</span>
        </p>
      </b-col>
    </b-row>
        
    <b-row class="mb-5" align-h="center">

      <b-col md="4" :class="[isVisibleCard1 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']" >
        <b-card class="p-0" no-body>
          <b-card-body class="p-0">
              <img src="@/assets/img/basic.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card" v-b-visible="handleVisibility1">lite</h3>
              <p class="h1 price-card mb-3">$269</p>
              <p class="text-center price-card-text mb-4" >{{ $t('home.verified') }} <br>{{ $t('home.paypal') }}</p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col  md="1" cols="1" ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10" cols="10">{{ $t('home.full_access') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1" cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10" cols="10">{{ $t('home.documents') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1" cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10" cols="10">{{ $t('home.instructions') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1" cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10" cols="10">{{ $t('home.guarantee') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1" cols="1"  ><img src="@/assets/img/check.svg" width="15px" style="opacity:0"/></b-col><b-col md="10" cols="10"></b-col></b-row></li>
                    
                  </ul>
              </div>

              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase" disabled @click="confirmPay(1)"><!--{{ $t('home.buy') }}-->Sold out</b-button>
              </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4" :class="[isVisibleCard2 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']">
        <b-card class="p-0" no-body>
          <b-card-body class="p-0">
              <img src="@/assets/img/standart.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card"   v-b-visible="handleVisibility2">PRO</h3>
              <p class="h1 price-card mb-3">$629</p>
              <p class="text-center price-card-text mb-4">{{ $t('home.verified') }} <br> {{ $t('home.bank_account') }}</p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"   ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10"  >{{ $t('home.full_access') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.access_to_bank') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.documents') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.instructions') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"  ><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.guarantee') }}</b-col></b-row></li>
                    
                  </ul>
              </div>

              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase" disabled @click="confirmPay(2)"><!--{{ $t('home.buy') }}-->Sold out</b-button>
              </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="4" :class="[isVisibleCard3 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']"  >
        <b-card class="p-0" no-body>
          <b-card-body class="p-0">
              <img src="@/assets/img/pro.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card"  style="color:#fff;" v-b-visible="handleVisibility3">EXPERT</h3>
              <p class="h1 price-card mb-3">$599</p>
              <p class="text-center price-card-text mb-4">{{ $t('home.three_verified') }}<br> {{ $t('home.accounts') }}</p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.full_access') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.documents') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.instructions') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.guarantee') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px" style="opacity: 0;"/></b-col><b-col md="10"  cols="10"></b-col></b-row></li>
                    
                  </ul>
              </div>

              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase" disabled @click="confirmPay(3)"><!--{{ $t('home.buy') }}-->Sold out</b-button>
              </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="4" :class="[isVisibleCard3 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']"  >
        <b-card class="p-0" no-body>
          <b-card-body class="p-0">
              <img src="@/assets/img/standart.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card"   v-b-visible="handleVisibility3">PERSONAL</h3>
              <p class="h1 price-card mb-3">$89</p>
              <p class="text-center price-card-text mb-4">{{ $t('home.personal_p1') }}</p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"></b-col><b-col md="10"  cols="10">{{ $t('home.personal_desc') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.full_access') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.instructions') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('home.guarantee') }}</b-col></b-row></li>
                    
                  </ul>
              </div>

              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase"  @click="confirmPay(8)">{{ $t('home.buy') }}</b-button>
              </div>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
    <b-row class="mb-5" align-h="center">
      <b-col md="6" :class="[isVisibleCard3 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']"  v-if="isLoggedIn()">
        <b-card class="p-0 h-100" no-body>
          <b-card-body class="p-0 h-100 d-flex justify-content-between flex-column">
            <div>
              <img src="@/assets/img/bank.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card"  style="color:#fff;" v-b-visible="handleVisibility3">EU BANK+VC LITE</h3>
              <p class="h1 price-card mb-3"><span style="color:red" class="mr-3">$349</span><small style="
    text-decoration: line-through;
">$439</small></p>
              <p class="text-center price-card-text mb-4">{{ $t('eubank.title') }} +&nbsp;{{ $t('eubank.title2') }} </p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p0') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p2') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p3') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p4') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p5') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p6') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px" style="opacity:0"/></b-col><b-col md="10"  cols="10"></b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"></b-col><b-col md="10"  cols="10"></b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"></b-col><b-col md="10"  cols="10">{{ $t('eubank.t2') }}</b-col></b-row></li>
                   
                    
                  </ul>
              </div>
              </div>
              <div>
              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase"  @click="confirmPay(7)">{{ $t('home.buy') }}</b-button>
              </div>
              </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" :class="[isVisibleCard3 ? 'animate__fadeInUp' : 'animate__fadeInUp', 'animate__animated', 'mb-4']"  v-if="isLoggedIn()">
        <b-card class="p-0 h-100" no-body>
          <b-card-body class="p-0">
              <img src="@/assets/img/bank.svg"  class="w-100"/>
              <h3 class="mb-0 text-uppercase text-center head-price-card"  style="color:#fff;" v-b-visible="handleVisibility3">EU BANK+VC</h3>
              <p class="h1 price-card mb-3">$519</p>
              <p class="text-center price-card-text mb-4">{{ $t('eubank.title') }} +&nbsp;{{ $t('eubank.title2') }} </p>
              <div class="pl-4 pr-4 pt-2 pb-2">
              <hr class="hr">
              </div>
              <div class="pl-4 pr-4 mb-4">
                  <ul class="list p-0">
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p0') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p2') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p3_1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p4_1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p5_1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p6_1') }}</b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"><img src="@/assets/img/check.svg" width="15px"/></b-col><b-col md="10"  cols="10">{{ $t('eubank.p7') }}</b-col></b-row></li>

                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"></b-col><b-col md="10"  cols="10"></b-col></b-row></li>
                    <li class="text-list"><b-row class="d-flex flex-row justify-content-start"><b-col md="1"  cols="1"></b-col><b-col md="10"  cols="10">{{ $t('eubank.t1') }}</b-col></b-row></li>
                    
                    
                  </ul>
              </div>

              <div class="pl-4 pr-4 mb-4 text-center">
              <b-button class="btn btn-primary btn-price-card w-75 text-uppercase" @click="confirmPay(4)">{{ $t('home.buy') }}</b-button>
              </div>
          </b-card-body>
        </b-card>
      </b-col>
       
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from "vuex";
import { httpClient } from "../HttpClientV1/HttpClient";
import i18n from "../i18n";
export default {
  name: "Home",
  data(){
    return{

        isVisibleCard1: false,
        isVisibleCard2: false,
        isVisibleCard3: false,
    }
  },
    methods: {
      ...mapGetters(
        [
          'isLoggedIn'
        ]
      ),
      handleVisibility1(isVisibleCard1) {
        this.isVisibleCard1 = isVisibleCard1
      },

      handleVisibility2(isVisibleCard2) {
        this.isVisibleCard2 = isVisibleCard2
      },

      handleVisibility3(isVisibleCard3) {
        this.isVisibleCard3 = isVisibleCard3
      },
      confirmPay(id){
        localStorage.setItem('account', id)
        this.$router.push('/confirmPay')
      },
      checkConfirm(){
      httpClient
        .get("api/users/check_confirm")
        .then((resp) => {
          if(resp.data.status == 'error'){
            this.$router.push('/user/email/confirm')
          }
          else{
            this.$router.push('/')
          }
        });
    },
    },
    mounted(){
      if(this.$route.path != '/user/activate'){
        if (this.$store.getters.isLoggedIn) {
          this.checkConfirm();
        }
      }
    }
};
</script>

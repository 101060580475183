<template>
  <b-row
    class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp mb-4 pb-4"
    style="height:100%"
  >
    <b-col md="6" class="text-center">
      <h1>{{ $t('auth.create_acc')}}</h1>
      <p>
        {{ $t('auth.p_create')}}
      </p>
      <b-row
        class="d-flex justify-content-center align-items-center align-content-center mt-5"
      >
        <b-col md="9">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form ref="Login" @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="E-Mail"
                :rules="{ required: true, email }"
                v-slot="validationContext"
              >
                <b-form-group class="mb-4 pb-1">
                  <b-form-input
                    v-model="email"
                    placeholder="Email"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback
                    class="mb-4"
                    id="input-1-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$i18n.t('auth.password')"
                :rules="{ required: true, min: 8 }"
                v-slot="validationContext"
              >
                <b-form-group class="mb-4 mt-4 pb-1">
                  <b-form-input
                    v-model="password"
                    type="password"
                    :placeholder="$i18n.t('auth.password')"
                     class="mt-3 "
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                :name="$i18n.t('auth.password')"
                :rules="{ required: true, min: 8 }"
                v-slot="validationContext"
              >
                <b-form-group class="mb-4 mt-4 pb-1">
                  <b-form-input
                   class="mt-3"
                    v-model="password2"
                    type="password"
                    :placeholder="$i18n.t('auth.password')"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                name="Telegram"
                :rules="{ required: true, min: 3 }"
                v-slot="validationContext"
              >
                <b-form-group class="mb-4 pb-1">
                  <b-form-input
                    v-model="telegram"
                   class="mt-3"
                    placeholder="Telegram"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback
                    class="mb-2"
                    id="input-1-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
              <b-button
                type="submit"
                variant="primary"
                class="btn-login mt-3 w-100 mb-4"
                :disabled="disabledBtn"
                >{{ $t('auth.sign_up')}}</b-button
              >
              <div v-if="errorLogin != null">
                <p v-if="errorLogin.email" style="color:red">
                  {{ errorLogin.email[0] }}
                </p>
                <p v-if="errorLogin.password" style="color:red">
                  {{ errorLogin.password[0] }}
                </p>
              </div>
            </b-form>
          </validation-observer>
          <router-link to="/user/login">{{ $t('auth.login')}}</router-link>
          <br>

          <a  href="#" class=" w-100 mb-2 mr-2" @click="setRuLocale"><img src="@/assets/img/ru.png" width="20" /></a>
          <a href="#"  class="w-100 mb-2 mr-2" @click="setEnLocale"><img src="@/assets/img/en.png" width="20" /></a>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      password2: "",
      telegram: "",
      errorLogin: null,
      disabledBtn: false
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.disabledBtn = true
      localStorage.removeItem("token");

      localStorage.removeItem("username");
      localStorage.removeItem("email");
      let user = {
        email: this.email,
        username: this.email,
        password: this.password,
        telegram: this.telegram,
        utm_source: localStorage.getItem("utm_source") || "",
        utm_medium: localStorage.getItem("utm_medium") || "",
        utm_campaign: localStorage.getItem("utm_campaign") || "",
        utm_content: localStorage.getItem("utm_content") || "",
      };
      this.$store
        .dispatch("register", { user })
        .then(() => (this.$router.push("/")))
        .catch((err) => (this.errorLogin = err.response.data.errors));
        this.disabledBtn = false
    },
    setRuLocale() {
      this.$i18n.locale = "ru";
      localStorage.setItem("locale", "ru");
      localize("ru", ru);
    },
    setEnLocale() {
      this.$i18n.locale = "en";
      localStorage.setItem("locale", "en");
      localize("en", en);
    },
  },
};
</script>

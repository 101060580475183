<template>
  <div class="mb-4">
    <div
      class="row d-flex align-item-center align-content-center"
      style="
        flex-direction: column;
        z-index: 900;
        margin-bottom: 20px;
        margin-top: 20px;
      "
    >
    </div>
    <div class="row mb-0 d-flex  flex-column align-item-center align-content-center text-center">
      <div class="col-md-12 col-xl-12 " style=" padding-bottom: 10px; border-radius: 15px" >
       <h2 class="mb-4">{{ $t('email.confirm_email')}}</h2>
       <p class="mb-4">{{ $t('email.p_confirm_email')}}</p>
       <p style="color:red">{{error}}</p>
       <p style="color:green">{{msg}}</p>
       <b-row>
         <b-col md="4" offset-md="2">
       <b-button class="btn btn-primary btn-price-card  w-100 mr-2 mb-4" @click="sendConfirm">{{ $t('email.resend')}}</b-button>
         </b-col>

         <b-col md="4">
       <b-button class="btn btn-primary btn-price-card  w-100  mr-2" @click="checkConfirm">{{ $t('email.check')}}</b-button>
         </b-col>
       </b-row>
      </div>
    </div>
    <!-- /.row -->
  </div>
</template>

<script>

import { httpClient } from "../HttpClientV1/HttpClient"
export default {
  name: "Login",
    data() {
    return {
      form: {
        username: null,
        email: null,
        password: null,
        password2: null,
        telegram: null,
      },
      error: null,
      msg: null,

    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null,
        password2: null,
        telegram: null,
      }

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    sendConfirm(){
      httpClient
        .get("api/users/send_confirm")
        .then((resp) => {
          if(resp.data.status == 'error'){
            console.log(resp.data.error)
            var err_str = ""
            if(this.$i18n.locale == 'ru'){
              err_str = resp.data.msg
            }
            else{
              err_str = resp.data.msg_en
            }
            
            this.error = err_str

              this.msg = null
          }
          else{
            this.error = null
              this.msg = resp.data.msg
            
          }
        });
    },
    checkConfirm(){
 httpClient
        .get("api/users/check_confirm")
        .then((resp) => {
          if(resp.data.status == 'error'){
            if(this.$i18n.locale == 'ru'){

              var err_str = resp.data.msg
            }
            else{

            var err_str = resp.data.msg_en
            }
            
              this.msg = null
            this.error = err_str
          }
          else{

            
            this.$router.push('/')
          }
        });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/user/login");
    },
    
  }
};
</script>

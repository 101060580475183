<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-start align-items-center align-content-center animate__animated animate__backInUp mb-5"
    >
      
      <b-col md="12" class="text-left" v-if="confirm != true">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <b-row
              class="d-flex justify-content-between align-items-center align-content-center pl-3 pr-3"
            >
              <h5
                class="mb-2 text-left"
                v-b-visible="handleVisibility2"
              >{{ $t('home.history_order') }}</h5>
              <b-button
                @click="buyAcc"
                type="submit"
                variant="primary"
                style="height:40px"
                class="btn-login mb-2 pt-2 pb-2"
              >{{ $t('home.buy_acc') }}</b-button>
            </b-row>
            <b-table
              v-if="rows > 0"
              id="tableInvoice"
              :items="tableData"
              :fields="tableColumn"
              :per-page="perPage"
              :current-page="currentPage"
              class="mt-4"
            >
              <template #cell(id)="data">
                <p>#{{ data.value }}</p>
              </template>
              <template #cell(link)="data">
                <b-row v-for="(item, index) in data.value" :key="index">
                  <a :href="item" target="_blank">{{ $t('home.open') }}</a>
                </b-row>
              </template>
            </b-table>
            <p v-else>{{ msg }}</p>
            <b-row v-if="rows > perPage">
              <b-col md="12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="tableInvoice"
                  class="justify-content-center"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { DotLoader } from "@saeris/vue-spinners";
import { httpClient } from "../HttpClientV1/HttpClient";
export default {
  components: {
    DotLoader
  },
  data() {
    return {
      tableData: null,
      rows: 0,
      ref_link: 'https://my.gctransfer.co/?ref=',
      perPage: 10,
      currentPage: 1,
      ref_balance: 0,
      msg: this.$i18n.t('home.No_data'),
      tableColumn: [
        {
          key: "id",
          label: 'Order id',
          sortable: false,
          class: "table-text-tran1",
        },
        {
          key: "created",
          label: "Date",
          sortable: false,
          class: "table-text-tran1",
        },
        {
          key: "link",
          label: "Link",
          sortable: false,
          class: "table-text-tran1",
        },
      ],
    };
  },
  methods: {
    buyAcc() {
      this.$router.push('/')
    },
    userData() {
      httpClient
        .post('api/user/data')
        .then((response) => {
          this.ref_link = 'https://my.gctransfer.co/?ref=' + response.data.code
          this.ref_balance = response.data.balance
        })
    },
    loadTableData() {
      httpClient
        .get("api/orders")
        .then((resp) => {
          this.tableData = resp.data
          this.rows = this.tableData.length
        })
        .catch(function (error) {
          if (error.response) {
            this.msg = error.response.data.msg
          }
        })
    }
  },
  mounted() {
    try {
      this.userData()
    }
    catch {
      console.log('Error loading data')
    }
    this.loadTableData()
  }
};
</script>

<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp mb-5">
      <b-col md="8" class="text-left" v-if="confirm != true">
        <h1>{{ $t('confirm_pay.pay_order') }}</h1>
        <p>
          {{ $t('confirm_pay.p_head_select_part1') }}<br>
          <span class="text-wrap">{{ $t('confirm_pay.p_head_select_part2') }}</span>
        </p>
      </b-col>
      <b-col md="8" class="text-left" v-if="confirm">
        <div v-if="step == 3">
          <h1 class="mb-5">{{ $t('confirm_pay.go_to_payment') }}</h1>

          <!--<b-row
            class="d-flex justify-content-center mt-4 mb-4 align-items-center mb-5"
          >
            <DotLoader :size="size" :color="color" />
          </b-row>-->
          <p>
            {{ $t('confirm_pay.p_after_pay') }}
          </p>
        </div>
        <div v-else>
          <h1 class="text-center">{{ $t('confirm_pay.number_order') }} #{{ order_id }}</h1>
          <b-row class="d-flex justify-content-center mt-4 mb-4 align-items-center">
            <div class="success-checkmark mt-4">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </b-row>
          <p class="text-center" v-if="selectedTafir != 2">
            {{ $t('confirm_pay.p_go_to_order') }}
          </p>

          <p class="text-center" v-else>
            {{ $t('confirm_pay.p_go_to_order_2') }}
          </p>

          <b-row class="d-flex justify-content-center mt-4 mb-4 align-items-center" v-if="selectedTafir != 2">
            <b-col md="6" lg="6">
              <b-button class="btn btn-primary btn-price-card  w-100" @click="goProfile">{{ $t('confirm_pay.orders') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="4" class="text-center" v-if="confirm != true">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <h5 class="mb-0 text-left " v-b-visible="handleVisibility2">
              {{ $t('confirm_pay.payment') }}
            </h5>
            <b-form>
              <b-form-group class="mt-2">
                <b-form-select v-model="selected" :options="options" @input="calculate">
                </b-form-select>
              </b-form-group>

              <b-form-group class="mt-2">
                <b-form-select v-model="selectedTafir" :options="optionsTarif" @input="calculate">
                </b-form-select>
              </b-form-group>

              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center">
                <b-input v-model="amount" :disabled="disabled_input" min="50" type="number" @input="calculate">
                </b-input>
                <img src="@/assets/img/dollar.png" width="20" style="position: absolute;right: 56%;opacity:1" />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled> </b-input>

                <img src="@/assets/img/rub.svg" width="20" v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:0.4" />
              </div>

              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center">
                {{$t('comission')}}
                </div>
              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center" @click="openModal"> <span
                  class="P1Text me-4 me-sm-0" v-if="promocode == undefined"
                  style="cursor:pointer;text-decoration: underline;">{{ $t('modal2.haveAPromo')
                  }}</span> <span class="P1Text me-4 me-sm-0" v-else style="cursor:pointer;text-decoration: none;">
                  <b>{{ $t('modal2.appliedPromo') }} <span style="color:#5598fb">{{ promocode.toUpperCase() }}</span></b>
                </span></div>
              <b-button class="btn btn-primary btn-price-card w-75 mb-2" :disabled="active == 0" @click="nextStepPay">
                {{ $t('confirm_pay.confirm') }}</b-button>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4" class="text-center" v-if="confirm & (step != 4)">
        <b-card class="p-4 mt-5" no-body>
          <b-card-body class="p-0">
            <h5 class="mb-0 text-left " v-b-visible="handleVisibility2">
              {{ $t('confirm_pay.payment') }}
            </h5>
            <b-form>
              <b-form-group class="mt-2">
                <b-form-select v-model="selected" disabled :options="options" @input="calculate">
                </b-form-select>
              </b-form-group>

              <b-form-group class="mt-2">
                <b-form-select v-model="selectedTafir" disabled :options="optionsTarif" @input="calculate">
                </b-form-select>
              </b-form-group>

              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center">
                <b-input v-model="amount" disabled> </b-input>
                <img src="@/assets/img/dollar.png" width="20" style="position: absolute;right: 56%;opacity:1" />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled> </b-input>

                <img src="@/assets/img/rub.svg" width="20" v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:0.4" />
              </div>

              <a v-if="selected == 'Mastercard (комиссия)'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>

              <a v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>
              <a v-if="selected == 'Qiwi (Wallet) - AliKassa'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>
              <a v-if="selected == 'юMoney (Wallet) - AliKassa'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>
              <a v-if="selected == 'Card (Visa/MasterCard) - AliKassa'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>
              <a v-else-if="selected == 'Qiwi'" :href="link" target="_blank"
                class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t('confirm_pay.pay') }}</a>

              <form v-else-if="selected == 'юMoney'" method="POST" formtarget="_blank" target="_blank"
                action="https://yoomoney.ru/quickpay/confirm.xml">
                <input type="hidden" name="receiver" value="4100116619370412" />

                <input type="hidden" name="label" :value="order_id" />
                <input type="hidden" name="quickpay-form" value="donate" />
                <input type="hidden" name="targets" :value="'Транзакция ' + order_id" />
                <input type="hidden" name="sum" :value="rub" data-type="number" />
                <input type="hidden" name="comment" value="Оплата аккаунта" />
                <input type="hidden" name="need-fio" value="false" />
                <input type="hidden" name="need-email" value="true" />
                <input type="hidden" name="need-phone" value="false" />
                <input type="hidden" name="need-address" value="false" />
                <b-row class="d-flex justify-content-center flex-column mt-4 mb-4 align-items-center">
                  <label><input type="radio" name="paymentType" value="PC" class="mr-2" />ЮMoney</label>
                  <label><input type="radio" name="paymentType" value="AC" class="mr-2" />Visa/MC</label>

                  <input type="submit" class="btn btn-primary btn-price-confirm w-75 mb-2" value="Перевести" />
                </b-row>
              </form>
              <p v-if="
                (selected != 'Mastercard (комиссия)') &
                (selected != 'Qiwi') &
                (selected != 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud') &
                (selected != 'юMoney') &
                (selected != 'Qiwi (Wallet) - AliKassa') &
                (selected != 'юMoney (Wallet) - AliKassa') &
                (selected != 'Card (Visa/MasterCard) - AliKassa')
              ">
                {{ $t('confirm_pay.wallet') }}:
              </p>
              <p class="crypto_address user-select-all font-monospace" v-if="
                (selected != 'Mastercard (комиссия)') &
                (selected != 'Qiwi') &
                (selected != 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud') &
                (selected != 'юMoney') &
                (selected != 'Qiwi (Wallet) - AliKassa') &
                (selected != 'юMoney (Wallet) - AliKassa') &
                (selected != 'Card (Visa/MasterCard) - AliKassa')
              ">
                {{ address }}
              </p>

              <p v-if="
                (selected != 'Mastercard (комиссия)') &
                (selected != 'Qiwi') &
                (selected != 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud') &
                (selected != 'юMoney') &
                (selected != 'Qiwi (Wallet) - AliKassa') &
                (selected != 'юMoney (Wallet) - AliKassa') &
                (selected != 'Card (Visa/MasterCard) - AliKassa')
              ">
                {{ $t('confirm_pay.amount') }}:
              </p>
              <p class="crypto_address user-select-all font-monospace" v-if="
                (selected != 'Mastercard (комиссия)') &
                (selected != 'Qiwi') &
                (selected != 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud') &
                (selected != 'юMoney') &
                (selected != 'Qiwi (Wallet) - AliKassa') &
                (selected != 'юMoney (Wallet) - AliKassa') &
                (selected != 'Card (Visa/MasterCard) - AliKassa')
              ">
                {{ rub }}
              </p>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <InputPromocode></InputPromocode>
  </div>
</template>

<script>
// @ is an alias to /src
import { httpClient } from "../HttpClientV1/HttpClient";
import InputPromocode from "../components/InputPromocode.vue";
export default {
  name: "Home",
  data() {
    return {
      isVisibleCard1: false,
      isVisibleCard2: false,
      isVisibleCard3: false,
      selected: null,
      confirm: false,
      error_pay: null,
      amount: 0,
      address: null,
      percent: null,
      rub: 0,
      active: 0,
      step: 2,
      link: null,
      disabled_input: true,
      size: 90,
      interval: null,
      color: "#5598fb",
      amount_pay: null,
      order_id: null,
      options: [
        { value: null, text: this.$i18n.t("confirm_pay.choose_payment") },
        // { value: "Qiwi", text: "Qiwi / Card" },
        // { value: "Mastercard (комиссия)", text: "Mastercard (комиссия)" },
        //  { value: "юMoney", text: "юMoney" },
        { value: "Card (Visa/MasterCard) - AliKassa", text: "Card (Visa/MasterCard) - AliKassa" },
        { value: "Qiwi (Wallet) - AliKassa", text: "Qiwi (Wallet) - AliKassa" },
        { value: "юMoney (Wallet) - AliKassa", text: "юMoney (Wallet) - AliKassa" },
        { value: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud", text: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud" },
        //{ value: "ltc", text: "LTC" },
      ],
      selectedTafir: null,
      optionsTarif: [
        { value: null, text: this.$i18n.t("confirm_pay.choose_plan") },
        // { value: 1, text: "LITE" },
        // { value: 2, text: "PRO" },
        // { value: 3, text: "EXPERT" },
        { value: 8, text: "PERSONAL" },
        { value: 4, text: "\tEU BANK+VC" },
        { value: 7, text: "\tEU BANK+VC LITE" },
        { value: 5, text: this.$i18n.t("custom_amount") },
      ],
    };
  },
  methods: {

    openModal() {
      this.$root.$emit('bv::show::modal', 'modal2', '#my-modal2')
    },
    handleVisibility1(isVisibleCard1) {
      this.isVisibleCard1 = isVisibleCard1;
    },
    handleVisibility2(isVisibleCard2) {
      this.isVisibleCard2 = isVisibleCard2;
    },
    handleVisibility3(isVisibleCard3) {
      this.isVisibleCard3 = isVisibleCard3;
    },
    goProfile() {
      this.$router.push("/profile");
    },
    calculate() {
      console.log(this.selectedTafir);
      this.active = 0;
      if (this.selectedTafir == null) {
        this.selectedTafir = 5;
      }
      if (this.selectedTafir == 1) {
        this.amount = 269;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 2) {
        this.amount = 629;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 3) {
        this.amount = 599;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 4) {
        this.amount = 519;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 7) {
        this.amount = 349;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 8) {
        this.amount = 89;
        this.disabled_input = true;
      }
      else if (this.selectedTafir == 5) {
        this.disabled_input = false;
      }
      if(this.selectedTafir != 5){
        
        if(this.percent != null){
          var sale =  this.amount * this.percent
          console.log(sale, this.amount, this.percent );
          this.amount = this.amount - sale
        }

      }
      localStorage.setItem("account", this.selectedTafir);
      if (this.selected != null && this.selectedTafir != null) {
        httpClient
          .get("api/converCurrensy?cur=" + this.selected + "&amount=" + this.amount)
          .then((resp) => ((this.rub = resp.data.val), (this.active = 1)));
      }
    },
    getInfoPromocode() {
      if (this.promocode != undefined) {
        httpClient.get('api/promocode/getInfo?promocode=' + this.promocode)
          .then((response) => {
            if (response.data.status == 'success') {
              this.percent =  response.data.percent
              this.calculate()
            }
          })
          .catch(() => {
            this.percent = null
          })
      }
      else {
        this.percent = null
      }
    },
    nextStepPay() {
      if (this.selected != null && this.selectedTafir != null) {
        httpClient
          .post("api/confirmPay", {
            type_pay: this.selected,
            amount: this.amount,
            plan_id: this.selectedTafir,
          })
          .then((resp) => {
            if (resp.data.status != "error") {
              (this.link = resp.data.link),
                (this.address = resp.data.address),
                (this.step = 3),
                localStorage.setItem("order", resp.data.order_id),
                (this.order_id = resp.data.order_id),
                (this.rub = resp.data.ammount_rub),
                (this.amount = resp.data.ammount_usd),
                this.loadQR(this.selected),
                (this.loadedQR = true),
                (this.confirm = true);
                localStorage.removeItem("promocode")
              this.sendConfirm();
            }
            else {
              this.error_pay = resp.data.msg;
            }
          });
      }
      else {
        if (this.selected == null) {
          this.error_pay = this.$i18n.t("confirm_pay.choose_payment");
        }
        else {
          this.error_pay = this.$i18n.t("confirm_pay.specify_plan");
        }
      }
    },
    sendConfirm() {
      var self = this;
      self.interval = setInterval(function () {
        httpClient
          .get("api/statusorder_confirm?id=" + localStorage.getItem("order"))
          .then((resp) => {
            if (resp.data.status == "ok") {
              if (self.step != 4) {
                (self.step = 4),
                  self.confirms(),
                  (self.order_id = localStorage.getItem("order"));
                window.clearInterval(self.interval);
              }
            }
          });
      }, 2000);
    },
    confirms() { },
    loadQR(type) {
      var order_id = localStorage.getItem("order");
      if (type == "ltc") {
        this.qr_url =
          "https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=" +
          this.address +
          "?amount=" +
          this.amount_pay +
          "&label=LTC&message=Пополнение баланса личного кабинета";
      }
      else if (type == "btc") {
        this.qr_url =
          "https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=" +
          this.address +
          "?amount=" +
          this.amount_pay +
          "&label=BTC&message=Пополнение баланса личного кабинета";
      }
      else if (type == "visa/mc") {
        this.qr_url =
          "https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=" +
          base_url +
          "/easypay/reirectPay?id=" +
          order_id;
        var self = this;
        if (self.step != 3) {
          self.interval = setInterval(function () {
            httpClient
              .get("easypay/statusorder?id=" + order_id)
              .then((resp) => {
                if (resp.data.status == "ok") {
                  if (order_id == localStorage.getItem("order")) {
                    if (self.step != 3) {
                      (self.step = 3),
                        (self.confirms(), window.clearInterval(self.interval));
                    }
                  }
                }
              });
          }, 1000);
        }
      }
    },
  },
  mounted() {
    this.selectedTafir = localStorage.getItem("account") || null;
    this.calculate();
    this.getInfoPromocode()
  },
  computed: {
    promocode() {
      return localStorage.getItem('promocode')
    }
  },
  components: { InputPromocode }
};
</script>

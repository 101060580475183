<template>
    <b-row class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp "  style="height:100%">
        <b-col  md="6" class="text-center">
            <h1>Восстановление пароля</h1>
            <p>Укажиет Email и мы отправим вам ссылку для Восстановления пароля вам на почту.</p>
            <b-row class="d-flex justify-content-center align-items-center align-content-center  mt-5">
                <b-col md="9">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form ref="Login" @submit.stop.prevent="handleSubmit(onSubmit)">


                            <validation-provider
                            name="E-Mail"
                            :rules="{ required: true, email }"
                            v-slot="validationContext">
                                <b-form-group class="mb-4 pb-1 text-center">
                                    <b-form-input v-model="email" placeholder="Email"
                                        :state="getValidationState(validationContext)"/>

                                    <b-form-invalid-feedback  class="mb-2 text-center" id="input-1-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                                </b-form-group>

                            </validation-provider>
                           
                            <b-button type='submit' variant="primary" class="btn-login w-100 mb-4">Зарегистрироваться</b-button>
                        </b-form>
                    </validation-observer>
                    <router-link to="/user/login">Войти</router-link>
                </b-col>
            </b-row>
        </b-col>
        
    </b-row>
</template>

<script>
export default {
    data(){
        return{
            username: '',
            email: '',
            password: '',
            password2: '',
            telegram: ''
        }
    },
    methods:{
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
    }
}
</script>
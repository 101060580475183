<template>
    <div class="mb-5">
    <b-row
      class="d-flex justify-content-center align-items-center align-content-center animate__animated animate__backInUp mb-5"
    >
      <b-col md="8" class="text-center" v-if="confirm != true">
          <div v-if="is_error">
              <h1>{{ $t('email.activation_error')}}</h1>
              <p>{{error}}</p>
          </div>
          <div v-else>
        <h1>{{ $t('email.acc_activation')}}</h1>
          <b-row
            class="d-flex justify-content-center mt-4 mb-4 align-items-center mb-5"
          >
            <DotLoader :size="size" :color="color" />
          </b-row>
          </div>
      </b-col>
      <b-col md="8" class="text-center" v-if="confirm">

        <h1>{{ $t('email.acc_activated')}}</h1>
             <b-row
            class="d-flex justify-content-center mt-4 mb-4 align-items-center"
          >
            <div class="success-checkmark mt-4">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </b-row>
              <b-row>
         <b-col md="6" offset-md="3" lg="4" offset-lg="4">
       <b-button class="btn btn-primary btn-price-card  w-100" @click="home">{{ $t('email.proceed_to_payment')}}</b-button>
         </b-col>

        
       </b-row>
          
      </b-col>
    </b-row>
    </div>
</template>
<script>
// @ is an alias to /src
import { DotLoader } from "@saeris/vue-spinners";
import { httpClient } from "../HttpClientV1/HttpClient";
export default {
  name: "Home",
  components: {
    DotLoader,
  },
  data() {
    return {
      confirm: false,
      size: 90,
      color: "#5598fb",
      error: null,
      is_error: false,
    }
  },
  methods:{
      home(){
          this.$router.push('/')
      },
      activateAccount(){
        httpClient
        .get("api/users/activate?key="+ this.$route.query.key)
        .then((resp) => {
          if(resp.data.status == 'error'){
            if(this.$i18n.locale == 'ru'){

              var err_str = resp.data.msg
            }
            else{

            var err_str = resp.data.msg_en
            }
            
            this.error = err_str
            this.is_error = true
          }
          else{

            
            this.is_error = false
            this.confirm = true
          }
        }); 
      }
  },
  mounted(){
      var self = this
      setTimeout(function(){self.activateAccount()},3000)

  }
}
</script>